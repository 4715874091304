import { Defaults } from "../../../defaults";
import moment from "moment";
export class Usuarios {
    initDatatable() {
        $('table').DataTable({
            ajax: {
                url: 'user/list',
                dataSrc: Defaults.global.dt.ajax.dataSrc('users')
            },
            columnDefs: Defaults.global.dt.getColumns([
                {
                    title: 'Nombre',
                    data: 'name'
                },
                {
                    title: 'Correo Electrónico',
                    data: 'email'
                },
                {
                    title: 'Ultimo Inicio de Sesión',
                    data: 'last_login',
                    render: (data, type) => {
                        if (type === 'display') {
                            return data ? moment(data).format('DD/MM/YYYY h:mma') : '-';
                        }
                        return data;
                    }
                }
            ])
        });
    }
}
