import { Defaults } from "../../defaults";
export class Departamentos {
    constructor() {
        this.options = {
            acceptedFileTypes: ['image/*'],
            server: {
                load: `departamentos/load?file=`,
                revert: 'revert'
            }
        };
    }
    initFilePond() {
        const options = this.options;
        $('.file-link').each((i, element) => {
            const options = this.options;
            const index = $(element).data('index');
            options.allowImagePreview = false;
            options.acceptedFileTypes = ['application/*'];
            Defaults.filePond(element, [$(`#links-src-${index}`).val().toString()], options);
        });
        Defaults.filePond($("#mainImage").get(0), [$("#mainImageSource").val().toString()], options);
        Defaults.filePond($("#secondaryImage").get(0), [$("#secondaryImageSource").val().toString()], options);
        Defaults.filePond($("#galeria").get(0), $("#galeriaSource").val().toString().split(','), options);
        options.acceptedFileTypes = ['application/*'];
        Defaults.filePond($("#publicaciones").get(0), $("#publicacionesSource").val().toString().split(','), options);
    }
}
