var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Defaults } from "../../../defaults";
import "bootstrap-treeview/dist/bootstrap-treeview.min.css";
import "bootstrap-treeview/dist/bootstrap-treeview.min.js";
import { modules } from "../../../../../config.json";
export class Perfiles {
    initTreeView(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield $.ajax({
                url: 'permission/data',
                data: { id }
            });
            this.user_permissions = response.data['user_permissions'];
            $('#tree').treeview({
                data: (() => {
                    const treeview = [];
                    this.generateTree(modules, treeview);
                    return treeview;
                })(),
                showCheckbox: true,
                onNodeChecked: function (event, node) {
                    $.ajax({
                        url: 'permission/data/' + id,
                        method: 'patch',
                        data: { module: node.metadata.module, status: true }
                    });
                },
                onNodeUnchecked: function (event, node) {
                    $.ajax({
                        url: 'permission/data/' + id,
                        method: 'patch',
                        data: { module: node.metadata.module, status: false }
                    });
                }
            });
        });
    }
    generateTree(modules, tree) {
        let cloneTree = tree;
        $.each(modules, (index, value) => {
            const { name, permissions, modules } = value;
            if (!name || permissions === false) {
                return;
            }
            const node = { text: name, selectable: false, state: { checked: false } };
            if (modules) {
                this.currentParent = index;
                node.state.checked = false;
                node.nodes = [];
                this.generateTree(modules, node.nodes);
            }
            else {
                if (this.currentParent) {
                    if (this.user_permissions.permissions[this.currentParent]) {
                        node.state.checked = this.user_permissions.permissions[this.currentParent][index];
                    }
                    node.metadata = { module: this.currentParent + '/' + index };
                }
                else {
                    node.state.checked = this.user_permissions.permissions[index];
                    node.metadata = { module: index };
                }
            }
            cloneTree.push(node);
        });
    }
    initDatatable() {
        $('table').DataTable({
            ajax: {
                url: 'permission/list',
                dataSrc: Defaults.global.dt.ajax.dataSrc('user_permissions')
            },
            columnDefs: Defaults.global.dt.getColumns([
                {
                    title: 'Nombre',
                    data: 'name'
                },
                {
                    title: 'Estatus',
                    data: 'status',
                    render(data, type) {
                        if (type === 'display') {
                            return data ? 'Activo' : 'Inactivo';
                        }
                        return data;
                    }
                },
                {
                    render(data, type, { id, name }) {
                        if (type === 'display') {
                            const btnEdit = `<button type="button" class="view btn btn-xs btn-info" data-id="${id}" data-name="${name}">
                                                <i class="material-icons">visibility</i>
                                             </button>`;
                            return `${btnEdit}`;
                        }
                        return null;
                    }
                }
            ]),
        });
    }
    view(id, title) {
        Defaults.openModal({
            title: title,
            url: 'perfiles/detalles?id=' + id
        });
    }
}
