export class Pastoral {
    addRow($section) {
        const id = $section.attr('id');
        const $hiddenRow = $('#hiddenRow').clone();
        const $title = $hiddenRow.find('input[type=text]');
        const $href = $hiddenRow.find('input[type=url]');
        $hiddenRow.attr('id', null);
        $hiddenRow.show();
        let currentIndex = +$("#currentIndex-" + id).val() + 1;
        const regex = /\[\]/gm;
        const subst = `[${currentIndex}]`;
        const title = $title.attr('name').replace(regex, subst);
        const href = $href.attr('name').replace(regex, subst);
        $title.attr('name', id + title);
        $href.attr('name', id + href);
        $("#currentIndex-" + id).val(currentIndex);
        $section.append($hiddenRow);
    }
    removeRow(e) {
        if (!confirm('Esta seguro?')) {
            return;
        }
        const $this = $(e.currentTarget);
        $this.parents('.links').remove();
    }
}
