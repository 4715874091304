import { Defaults } from "../../defaults";
export class NivelesEducativos {
    constructor() {
        this.options = {
            acceptedFileTypes: ['image/*'],
            server: {
                load: `nivelesEducativos/load?file=`,
                revert: 'revert'
            }
        };
    }
    initFilePond() {
        const options = this.options;
        Defaults.filePond($("#mainImage").get(0), [$("#mainImageSource").val().toString()], options);
        Defaults.filePond($("#secondaryImage").get(0), [$("#secondaryImageSource").val().toString()], options);
        Defaults.filePond($("#galeria").get(0), $("#galeriaSource").val().toString().split(','), options);
        $('.file-link').each((i, element) => {
            const options = this.options;
            const index = $(element).data('index');
            options.allowImagePreview = false;
            options.acceptedFileTypes = ['application/*'];
            Defaults.filePond(element, [$(`#links-src-${index}`).val().toString()], options);
        });
        $('.file-protocolo').each((i, element) => {
            const options = this.options;
            const index = $(element).data('index');
            options.allowImagePreview = false;
            options.acceptedFileTypes = ['application/*'];
            Defaults.filePond(element, [$(`#protocolos-src-${index}`).val().toString()], options);
        });
        $('.file-educacion').each((i, element) => {
            const options = this.options;
            const index = $(element).data('index');
            options.allowImagePreview = false;
            options.acceptedFileTypes = ['application/*'];
            Defaults.filePond(element, [$(`#educacion-a-distancia-src-${index}`).val().toString()], options);
        });
    }
    addRow($section) {
        const id = $section.attr('id');
        const $hiddenRow = $('#hiddenRow').clone();
        const $title = $hiddenRow.find('input[type=text]');
        const $href = $hiddenRow.find('input[type=file]');
        $hiddenRow.attr('id', null);
        $hiddenRow.show();
        let currentIndex = +$("#currentIndex-" + id).val() + 1;
        const regex = /\[\]/gm;
        const subst = `[${currentIndex}]`;
        const title = $title.attr('name').replace(regex, subst);
        const href = $href.attr('name').replace(regex, subst);
        $title.attr('name', id + title);
        $href.attr('name', id + href);
        $("#currentIndex-" + id).val(currentIndex);
        $section.append($hiddenRow);
        const options = this.options;
        const element = $href.get(0);
        options.allowImagePreview = false;
        options.acceptedFileTypes = [
            'application/*'
        ];
        Defaults.filePond(element, [], options);
    }
    removeRow(e) {
        if (!confirm('Esta seguro?')) {
            return;
        }
        const $this = $(e.currentTarget);
        $this.parents('.links').remove();
    }
}
