import { Defaults } from "../../defaults";
export class Inicio {
    constructor() {
        this.options = {
            acceptedFileTypes: ['image/*'],
            server: {
                load: `dashboard/load?file=`,
                revert: 'revert'
            }
        };
    }
    initFilePond() {
        const options = this.options;
        Defaults.filePond($("#slider").get(0), $("#sliderSource").val().toString().split(','), options);
        $('.identity-file-link').each((i, element) => {
            const options = this.options;
            const index = $(element).data('index');
            Defaults.filePond(element, [$(`#identity-img-src-${index}`).val().toString()], options);
        });
        $('.values-file-link').each((i, element) => {
            const options = this.options;
            const index = $(element).data('index');
            Defaults.filePond(element, [$(`#values-img-src-${index}`).val().toString()], options);
        });
    }
}
